import { ErrorBoundary as SentryErrorBoundary, setUser as setSentryUser } from "@sentry/react";
import i18next from "i18next";
import { FunctionComponent, lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import ErrorBoundaryFallback from "Components/Common/ErrorBoundaryFallback/ErrorBoundaryFallback";
import Loader from "Components/Common/Loader/Loader";
import OnboardingLayout from "Components/Onboarding/OnboardingLayout/OnboardingLayout";
import TunnelContainer from "Components/Tunnel/TunnelContainer";
import { AuthenticationProvider, useAuthentication } from "Context/AuthenticationContext";
import { SynchronizationProvider } from "Context/SynchronizationContext";
import { useProfileQuery } from "Domain/Profile";
import { GOOGLE_ANALYTICS_ID, REACT_QUERY_CONFIG } from "Settings";
import CurrentTheme from "Theme/CurrentTheme";
import { isDevelopment } from "Utils/Debug";

import { useMeQuery } from "./Domain/User";
declare global {
  interface Window {
    gtag: any;
  }
}
const QuoteSignature = lazy(() => import(/* webpackChunkName: "QuoteSignature" */ "Components/Quote/Signature"));
const Auth = lazy(() => import(/* webpackChunkName: "Auth" */ "Components/Auth"));
const EmailConfirmationStep = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingEmailConfirmationStep" */ "Components/Onboarding/Steps/EmailConfirmationStep/EmailConfirmationStep"
    ),
);
const Login = lazy(() => import(/* webpackChunkName: "Login" */ "Components/Authentication/Login"));
const Contact = lazy(() => import(/* webpackChunkName: "Contact" */ "Components/Contact"));
const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ "Components/Dashboard"));
const Files = lazy(() => import(/* webpackChunkName: "Files" */ "Components/Files"));
const Interventions = lazy(() => import(/* webpackChunkName: "Interventions" */ "Components/Interventions"));
const Invoices = lazy(() => import(/* webpackChunkName: "Invoices" */ "Components/Invoices"));
const ManageAccount = lazy(() => import(/* webpackChunkName: "ManageAccount" */ "Components/ManageAccount"));
const Onboarding = lazy(() => import(/* webpackChunkName: "Onboarding" */ "Components/Onboarding/Onboarding"));
const Welcome = lazy(() => import(/* webpackChunkName: "OnboardingWelcome" */ "Components/Onboarding/Welcome/Welcome"));
const Password = lazy(() => import(/* webpackChunkName: "Password" */ "Components/Authentication/Password"));
const ShortRedirect = lazy(
  () => import(/* webpackChunkName: "ShortRedirect" */ "Components/ShortRedirect/ShortRedirect"),
);
const SignUp = lazy(() => import(/* webpackChunkName: "SignUp" */ "Components/Authentication/SignUp"));

const TunnelStepOne = lazy(
  () => import(/* webpackChunkName: "TunnelStepOne" */ "Components/TunnelSteps/TunnelStepOne/TunnelStepOne"),
);
const TunnelStepTwo = lazy(
  () => import(/* webpackChunkName: "TunnelStepTwo" */ "Components/TunnelSteps/TunnelStepTwo"),
);
const TunnelStepThree = lazy(
  () => import(/* webpackChunkName: "TunnelStepThree" */ "Components/TunnelSteps/TunnelStepThree"),
);
const TunnelStepFour = lazy(
  () => import(/* webpackChunkName: "TunnelStepFour" */ "Components/TunnelSteps/TunnelStepFour"),
);
const TunnelStepFive = lazy(
  () => import(/* webpackChunkName: "TunnelStepFive" */ "Components/TunnelSteps/TunnelStepFive"),
);
const TunnelStepSix = lazy(
  () => import(/* webpackChunkName: "TunnelStepSix" */ "Components/TunnelSteps/TunnelStepSix"),
);

const InvoiceCustomerList = lazy(
  () => import(/* webpackChunkName: "InvoiceCustomerList" */ "Components/Invoices/InvoiceCustomerList"),
);

i18next.loadNamespaces("login");

const AnonymousRoute = ({ component: Component, ...rest }) => {
  const { isLogged, logout, contactId } = useAuthentication();
  const logoutPaths = ["reset_password", "finalize"];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const path = queryParams.get("path") || "/";

  const redirectTo = contactId ? "/" : path;
  useEffect(() => {
    // If reset_password token, logout
    if (rest.computedMatch.params && logoutPaths.includes(rest.computedMatch.params.action)) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return rest.computedMatch.params && logoutPaths.includes(rest.computedMatch.params.action) ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Route {...rest} render={(props) => (isLogged() ? <Redirect to={redirectTo} /> : <Component {...props} />)} />
  );
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLogged, contactId, logout } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    if (isLogged() && !contactId) {
      logout();
    }
  }, [isLogged, contactId, logout]);

  // redirect to ddefinition depends on contactId
  const redirectTo = contactId ? `/login?path=${location.pathname}` : "/login";
  return <Route {...rest} render={(props) => (isLogged() ? <Component {...props} /> : <Redirect to={redirectTo} />)} />;
};

const ProtectedRouteForCustomer = ({ component: Component, ...rest }) => {
  const { isLogged } = useAuthentication();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => (isLogged() ? <Component {...props} /> : <Redirect to={`/login?path=${location.pathname}`} />)}
    />
  );
};

const withErrorBoundary = (Component, Wrapper: unknown = OnboardingLayout) => {
  return (props) => {
    const { t } = useTranslation("default");

    return (
      <SentryErrorBoundary
        showDialog
        fallback={
          <ErrorBoundaryFallback
            buttonText={t("default:error.home")}
            title={t("default:error.title")}
            description={t("default:error.title2")}
            to="/"
            wrapper={Wrapper as FunctionComponent}
          />
        }
      >
        <Component {...props} />
      </SentryErrorBoundary>
    );
  };
};

const ProtectedBuyerRoute = withErrorBoundary(({ component: Component, ...props }) => {
  const { data, isLoading } = useProfileQuery();

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...props}
      render={(props) =>
        data?.hasCartPermission && !!data?.counters?.customers?.withReservationEnabled ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}, TunnelContainer);

const AnonymousRegisterRoute = withErrorBoundary(
  ({ component: Component, ...props }) => <Route {...props} render={(props) => <Component {...props} />} />,
  OnboardingLayout,
);

const AutologinResolveRedirect = ({ token }) => {
  const { autoLogin, logout } = useAuthentication();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    try {
      autoLogin(token, location, {
        onSuccess: () => {
          // make sure we're not connected as another user
          logout();

          // remove token from url
          const queryParams = new URLSearchParams(location.search);
          queryParams.delete("_auto");
          location.search = queryParams.toString();
          history.replace(location);
        },
      });
    } catch (e) {
      toast.error(t("login:error.token_expired"), {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {
          history.push("/login", { location });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
};

const AutologinSwitch = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const defaultTitle = t("default:meta.defaultTitle");
  const queryParams = new URLSearchParams(location.search);
  const autologinToken = queryParams.get("_auto");

  const helmet = (
    <Helmet titleTemplate={`%s - ${defaultTitle}`} defaultTitle={defaultTitle}>
      <meta charSet="utf-8" />
    </Helmet>
  );

  if (autologinToken) {
    return (
      <>
        {helmet}
        <AutologinResolveRedirect token={autologinToken} />
      </>
    );
  }

  return (
    <>
      {helmet}
      <Switch>{children}</Switch>
    </>
  );
};

/**
 * Hook that sets user into sentry for eventual error reporting
 */
function useSetSentryUserContext() {
  const { isLogged } = useAuthentication();
  const isUserLoggedIn = isLogged();
  const meQuery = useMeQuery({ enabled: isUserLoggedIn });
  useEffect(() => {
    if (isUserLoggedIn) {
      if (meQuery.isSuccess) {
        setSentryUser({ email: meQuery.data.email, name: meQuery.data.firstName + " " + meQuery.data.lastName });
      }
    } else {
      setSentryUser(null);
    }
  }, [isUserLoggedIn, meQuery.isSuccess, meQuery.data?.firstName, meQuery.data?.lastName, meQuery.data?.email]);
}

function App() {
  const { contactId } = useAuthentication();

  useSetSentryUserContext();

  useEffect(() => {
    if (window.gtag && GOOGLE_ANALYTICS_ID) {
      const payload = { send_page_view: false, ...(isDevelopment() ? { debug_mode: true } : {}) };

      if (contactId) {
        window.gtag("config", GOOGLE_ANALYTICS_ID, { ...payload, user_id: contactId });
      } else {
        window.gtag("config", GOOGLE_ANALYTICS_ID, payload);
      }
    }
  }, [contactId]);

  const loader = <Loader />;

  return (
    <Suspense fallback={loader}>
      <BrowserRouter>
        <ThemeProvider theme={CurrentTheme}>
          <SentryErrorBoundary showDialog fallback={() => <div></div>}>
            <SynchronizationProvider>
              <AutologinSwitch>
                <AnonymousRoute path="/login" component={Login} />
                <AnonymousRoute path="/signup" component={SignUp} />
                <AnonymousRoute path="/password" component={Password} />
                <AnonymousRoute path="/auth/:action" component={Auth} />
                <AnonymousRoute path="/register/success" component={EmailConfirmationStep} />
                <AnonymousRegisterRoute path="/register/:action" component={Onboarding} />
                <AnonymousRegisterRoute path="/register" component={Onboarding} />
                <ProtectedRoute path="/welcome" component={Welcome} />
                <ProtectedRoute path="/" exact component={Dashboard} />
                <Redirect from="/files/undefined" to="/files" />
                <ProtectedRoute path="/files" component={Files} />
                <ProtectedRoute path="/interventions" component={Interventions} />
                <ProtectedRoute path="/invoices" component={Invoices} />
                <ProtectedRouteForCustomer path="/customer/:customerCode/invoices" component={InvoiceCustomerList} />
                <ProtectedRoute path="/contact" exact component={Contact} />
                <ProtectedRoute path="/contact/:id" component={Contact} />
                <ProtectedRoute path="/account" component={ManageAccount} />
                <ProtectedRoute path="/quote/:id/signature" component={QuoteSignature} />
                <ProtectedBuyerRoute path="/booking/step-1" component={TunnelStepOne} />
                <ProtectedBuyerRoute path="/booking/step-2" component={TunnelStepTwo} />
                <ProtectedBuyerRoute path="/booking/step-3" component={TunnelStepThree} />
                <ProtectedBuyerRoute path="/booking/step-4" component={TunnelStepFour} />
                <ProtectedBuyerRoute path="/booking/step-5" component={TunnelStepFive} />
                <ProtectedBuyerRoute path="/booking/step-6" component={TunnelStepSix} />
                <Redirect from="/booking" to="booking/step-1" />
                <Route path="/s/:shortUrl" component={ShortRedirect} />
                <Redirect from="*" to="/" />
              </AutologinSwitch>
            </SynchronizationProvider>
          </SentryErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
}

const queryClient = new QueryClient(REACT_QUERY_CONFIG);

const AppWithQueryClientContext = () => (
  <QueryClientProvider client={queryClient}>
    <AuthenticationProvider>
      <App />
      <ToastContainer />
    </AuthenticationProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default AppWithQueryClientContext;
